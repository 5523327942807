import React, { useState } from "react";
import Filtro from "./Filtro";
import logoCicla from "../img/logo_cicla.svg"; // Ajusta la ruta de la imagen según tu estructura de carpetas

function Menu({ onFilterChange, filters }) {
  // Recibe onFilterChange y filters
  const [mostrarFiltro, setMostrarFiltro] = useState(false);

  const toggleFiltro = () => {
    setMostrarFiltro(!mostrarFiltro);
  };

  return (
    <div className="container">
      {/* <div className="header"> */}
      <div className="nav">
        <img src={logoCicla} alt="CICLA" className="logo" />
        <div className="filtro">
          <p className="filtro-texto">FILTRO</p>
          <button
            className={`burger ${mostrarFiltro ? "active" : ""}`}
            onClick={toggleFiltro}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
      {mostrarFiltro && (
        <Filtro onFilterChange={onFilterChange} filters={filters} /> // Pasa los filtros
      )}
      {/* </div> */}
    </div>
  );
}

export default Menu;
