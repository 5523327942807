// import React, { useState, useEffect } from "react";

// const Filtro = ({ onFilterChange }) => {
//   const [selectedTipo, setSelectedTipo] = useState("Todos");
//   const [selectedMes, setSelectedMes] = useState("Todos");
//   const [selectedAno, setSelectedAno] = useState("2023");

//   useEffect(() => {
//     onFilterChange({
//       type: selectedTipo,
//       month: selectedMes,
//       ano: selectedAno,
//     });
//   }, [onFilterChange, selectedTipo, selectedMes, selectedAno]);

//   const handleTipoChange = (tipo) => {
//     setSelectedTipo(tipo);
//   };

//   const handleMesChange = (mes) => {
//     setSelectedMes(mes);
//   };

//   const handleAnoChange = (ano) => {
//     setSelectedAno(ano);
//   };

//   return (
//     <div className="filter">
//       <div className="spacer"></div>
//       <div className="filter-month">
//         <div className="filter-type-title">
//           <h4>Año</h4>
//         </div>
//         <label>
//           <input
//             type="checkbox"
//             value="2023"
//             checked={selectedAno === "2023"}
//             onChange={() => handleAnoChange("2023")} // Marca "Todos"
//           />
//           2023
//         </label>
//         <label>
//           <input
//             type="checkbox"
//             value="2024"
//             checked={selectedAno === "2024"}
//             onChange={() => handleAnoChange("2024")}
//           />
//           2024
//         </label>
//       </div>

//       <div className="spacer"></div>
//       <div className="filter-month">
//         <div className="filter-type-title">
//           <h4>Mes</h4>
//         </div>
//         <label>
//           <input
//             type="checkbox"
//             value="Todos"
//             checked={selectedMes === "Todos"}
//             onChange={() => handleMesChange("Todos")} // Marca "Todos"
//           />
//           Todos
//         </label>
//         <label>
//           <input
//             type="checkbox"
//             value="Ene"
//             checked={selectedMes === "Ene"}
//             onChange={() => handleMesChange("Ene")}
//           />
//           Ene
//         </label>
//         <label>
//           <input
//             type="checkbox"
//             value="Feb"
//             checked={selectedMes === "Feb"}
//             onChange={() => handleMesChange("Feb")}
//           />
//           Feb
//         </label>
//         <label>
//           <input
//             type="checkbox"
//             value="Mar"
//             checked={selectedMes === "Mar"}
//             onChange={() => handleMesChange("Mar")}
//           />
//           Mar
//         </label>
//         <label>
//           <input
//             type="checkbox"
//             value="Abr"
//             checked={selectedMes === "Abr"}
//             onChange={() => handleMesChange("Abr")}
//           />
//           Abr
//         </label>
//         <label>
//           <input
//             type="checkbox"
//             value="May"
//             checked={selectedMes === "May"}
//             onChange={() => handleMesChange("May")}
//           />
//           May
//         </label>
//         <label>
//           <input
//             type="checkbox"
//             value="Jun"
//             checked={selectedMes === "Jun"}
//             onChange={() => handleMesChange("Jun")}
//           />
//           Jun
//         </label>
//         <label>
//           <input
//             type="checkbox"
//             value="Jul"
//             checked={selectedMes === "Jul"}
//             onChange={() => handleMesChange("Jul")}
//           />
//           Jul
//         </label>
//         <label>
//           <input
//             type="checkbox"
//             value="Ago"
//             checked={selectedMes === "Ago"}
//             onChange={() => handleMesChange("Ago")}
//           />
//           Ago
//         </label>
//         <label>
//           <input
//             type="checkbox"
//             value="Sep"
//             checked={selectedMes === "Sep"}
//             onChange={() => handleMesChange("Sep")}
//           />
//           Sep
//         </label>
//         <label>
//           <input
//             type="checkbox"
//             value="Oct"
//             checked={selectedMes === "Oct"}
//             onChange={() => handleMesChange("Oct")}
//           />
//           Oct
//         </label>
//         <label>
//           <input
//             type="checkbox"
//             value="Nov"
//             checked={selectedMes === "Nov"}
//             onChange={() => handleMesChange("Nov")}
//           />
//           Nov
//         </label>
//         <label>
//           <input
//             type="checkbox"
//             value="Dic"
//             checked={selectedMes === "Dic"}
//             onChange={() => handleMesChange("Dic")}
//           />
//           Dic
//         </label>{" "}
//       </div>

//       <div className="spacer"></div>
//       <div className="filter-type">
//         <div className="filter-type-title">
//           <h4>Tipo de Carrera</h4>
//         </div>
//         <div>
//           <label>
//             <input
//               type="checkbox"
//               value="Todos"
//               checked={selectedTipo === "Todos"}
//               onChange={() => handleTipoChange("Todos")} // Marca "Todos"
//             />
//             Todos
//           </label>
//           <label>
//             <input
//               type="checkbox"
//               value="Ruta"
//               checked={selectedTipo === "Ruta"}
//               onChange={() => handleTipoChange("Ruta")}
//             />
//             Ruta
//           </label>
//           <label>
//             <input
//               type="checkbox"
//               value="Rural"
//               checked={selectedTipo === "Rural"}
//               onChange={() => handleTipoChange("Rural")}
//             />
//             Rural
//           </label>
//           <label>
//             <input
//               type="checkbox"
//               value="Rally"
//               checked={selectedTipo === "Rally"}
//               onChange={() => handleTipoChange("Rally")}
//             />
//             Rally
//           </label>
//           <label>
//             <input
//               type="checkbox"
//               value="XCM"
//               checked={selectedTipo === "XCM"}
//               onChange={() => handleTipoChange("XCM")}
//             />
//             XCM
//           </label>
//           <label>
//             <input
//               type="checkbox"
//               value="XCC"
//               checked={selectedTipo === "XCC"}
//               onChange={() => handleTipoChange("XCC")}
//             />
//             XCC
//           </label>
//           <label>
//             <input
//               type="checkbox"
//               value="XCO"
//               checked={selectedTipo === "XCO"}
//               onChange={() => handleTipoChange("XCO")}
//             />
//             XCO
//           </label>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Filtro;

import React, { useState, useEffect } from "react";

const Filtro = ({ onFilterChange }) => {
  // Leer los valores de filtros desde localStorage
  const savedFilters = JSON.parse(localStorage.getItem("racefinder_filters"));

  // Inicializar los valores de filtros con los valores guardados o con valores por defecto
  const [selectedTipo, setSelectedTipo] = useState(
    savedFilters?.type || "Todos"
  );
  const [selectedMes, setSelectedMes] = useState(
    savedFilters?.month || "Todos"
  );
  const [selectedAno, setSelectedAno] = useState(savedFilters?.ano || "Todos");

  const updateFilters = () => {
    const newFilters = {
      type: selectedTipo,
      month: selectedMes,
      ano: selectedAno,
    };
    onFilterChange(newFilters);
    // Guardar los valores de filtros en localStorage
    localStorage.setItem("racefinder_filters", JSON.stringify(newFilters));
  };

  // useEffect(updateFilters, [selectedTipo, selectedMes, selectedAno]);
  useEffect(updateFilters, [
    selectedTipo,
    selectedMes,
    selectedAno,
    onFilterChange,
  ]);

  const handleTipoChange = (tipo) => {
    setSelectedTipo(tipo);
  };

  const handleMesChange = (mes) => {
    setSelectedMes(mes);
  };

  const handleAnoChange = (ano) => {
    setSelectedAno(ano);
  };

  return (
    <div className="filter">
      <div className="spacer"></div>
      <div className="filter-month">
        <div className="filter-type-title">
          <h4>Año</h4>
        </div>
        <label>
          <input
            type="checkbox"
            value="Todos"
            checked={selectedAno === "Todos"}
            onChange={() => handleAnoChange("Todos")} // Marca "Todos"
          />
          Todos
        </label>
        <label>
          <input
            type="checkbox"
            value="2024"
            checked={selectedAno === "2024"}
            onChange={() => handleAnoChange("2024")}
          />
          2024
        </label>
      </div>

      <div className="spacer"></div>
      <div className="filter-month">
        <div className="filter-type-title">
          <h4>Mes</h4>
        </div>
        <label>
          <input
            type="checkbox"
            value="Todos"
            checked={selectedMes === "Todos"}
            onChange={() => handleMesChange("Todos")} // Marca "Todos"
          />
          Todos
        </label>
        <label>
          <input
            type="checkbox"
            value="Ene"
            checked={selectedMes === "Ene"}
            onChange={() => handleMesChange("Ene")}
          />
          Ene
        </label>
        <label>
          <input
            type="checkbox"
            value="Feb"
            checked={selectedMes === "Feb"}
            onChange={() => handleMesChange("Feb")}
          />
          Feb
        </label>
        <label>
          <input
            type="checkbox"
            value="Mar"
            checked={selectedMes === "Mar"}
            onChange={() => handleMesChange("Mar")}
          />
          Mar
        </label>
        <label>
          <input
            type="checkbox"
            value="Abr"
            checked={selectedMes === "Abr"}
            onChange={() => handleMesChange("Abr")}
          />
          Abr
        </label>
        <label>
          <input
            type="checkbox"
            value="May"
            checked={selectedMes === "May"}
            onChange={() => handleMesChange("May")}
          />
          May
        </label>
        <label>
          <input
            type="checkbox"
            value="Jun"
            checked={selectedMes === "Jun"}
            onChange={() => handleMesChange("Jun")}
          />
          Jun
        </label>
        <label>
          <input
            type="checkbox"
            value="Jul"
            checked={selectedMes === "Jul"}
            onChange={() => handleMesChange("Jul")}
          />
          Jul
        </label>
        <label>
          <input
            type="checkbox"
            value="Ago"
            checked={selectedMes === "Ago"}
            onChange={() => handleMesChange("Ago")}
          />
          Ago
        </label>
        <label>
          <input
            type="checkbox"
            value="Sep"
            checked={selectedMes === "Sep"}
            onChange={() => handleMesChange("Sep")}
          />
          Sep
        </label>
        <label>
          <input
            type="checkbox"
            value="Oct"
            checked={selectedMes === "Oct"}
            onChange={() => handleMesChange("Oct")}
          />
          Oct
        </label>
        <label>
          <input
            type="checkbox"
            value="Nov"
            checked={selectedMes === "Nov"}
            onChange={() => handleMesChange("Nov")}
          />
          Nov
        </label>
        <label>
          <input
            type="checkbox"
            value="Dic"
            checked={selectedMes === "Dic"}
            onChange={() => handleMesChange("Dic")}
          />
          Dic
        </label>{" "}
      </div>

      <div className="spacer"></div>
      <div className="filter-type">
        <div className="filter-type-title">
          <h4>Tipo de Carrera</h4>
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              value="Todos"
              checked={selectedTipo === "Todos"}
              onChange={() => handleTipoChange("Todos")} // Marca "Todos"
            />
            Todos
          </label>
          <label>
            <input
              type="checkbox"
              value="Ruta"
              checked={selectedTipo === "Ruta"}
              onChange={() => handleTipoChange("Ruta")}
            />
            Ruta
          </label>
          <label>
            <input
              type="checkbox"
              value="Rural"
              checked={selectedTipo === "Rural"}
              onChange={() => handleTipoChange("Rural")}
            />
            Rural
          </label>
          <label>
            <input
              type="checkbox"
              value="Rally"
              checked={selectedTipo === "Rally"}
              onChange={() => handleTipoChange("Rally")}
            />
            Rally
          </label>
          <label>
            <input
              type="checkbox"
              value="XCM"
              checked={selectedTipo === "XCM"}
              onChange={() => handleTipoChange("XCM")}
            />
            XCM
          </label>
          <label>
            <input
              type="checkbox"
              value="XCC"
              checked={selectedTipo === "XCC"}
              onChange={() => handleTipoChange("XCC")}
            />
            XCC
          </label>
          <label>
            <input
              type="checkbox"
              value="XCO"
              checked={selectedTipo === "XCO"}
              onChange={() => handleTipoChange("XCO")}
            />
            XCO
          </label>
        </div>
      </div>
    </div>
  );
};

export default Filtro;
