import React from "react";

//
function Intro() {
  return (
    <section className="intro">
      <div className="intro-title">
        <h2>
          Cicla es un proyecto que recopila todas las competencias de ciclismo
          en Argentina.
        </h2>
        <p>
          Si sabés de alguna competencia que no figura en el listado o sos
          organizador y tu evento no aparece, completá la ficha con los datos
          del evento.
          {/* <div className="mailto">
            {" "}
            <a href="mailto:eventos@cicla.app?subject=Registrar%20nuevo%20evento">
              Enviar evento
            </a>
          </div> */}
          <div className="ficha">
            {" "}
            <a href="./form/formulario.html">Ficha</a>
          </div>
        </p>
      </div>
      <div></div>
    </section>
  );
}

export default Intro;
