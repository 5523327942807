import React, { useState, useEffect } from "react";
import competenciasData from "../json/competencias.json";

const Cards = ({ filters }) => {
  const [displayedCompetencias, setDisplayedCompetencias] = useState([]);
  const [noResults, setNoResults] = useState(false); // Agrega el estado noResults

  useEffect(() => {
    const initialFilters = { type: "Todos", month: "Todos", ano: "Todos" };
    filterCompetencias(initialFilters);
  }, []); // Esto se ejecuta al montar el componente

  useEffect(() => {
    filterCompetencias(filters);
  }, [filters]);

  const filterCompetencias = (filters) => {
    const displayedCompetencias = competenciasData.filter((competencia) => {
      if (
        filters.type !== "Todos" &&
        competencia.type.toLowerCase() !== filters.type.toLowerCase()
      ) {
        return false;
      }
      if (
        filters.month !== "Todos" &&
        competencia.month.toLowerCase() !== filters.month.toLowerCase()
      ) {
        return false;
      }
      if (filters.ano !== "Todos" && competencia.ano !== filters.ano) {
        return false;
      }

      return true;
    });

    if (displayedCompetencias.length === 0) {
      setNoResults(true);
    } else {
      setNoResults(false);
    }

    setDisplayedCompetencias(displayedCompetencias);
  };

  return (
    <div>
      {noResults ? (
        <div className="no-results">
          <p className="no-results-title">
            Nada por ahora...
            <br />A seguir entrenando
          </p>
          <p className="no-results-text">
            Si sabés de alguna competencia,<br></br>
            por favor compartila a través del formulario:<br></br>
            carreras@racefinder.com
          </p>
        </div>
      ) : (
        <div className="events-container">
          {displayedCompetencias.map((competencia, index) => (
            <article
              key={index}
              className={`card ${competencia.type.toLowerCase()}`}
            >
              <div className="card-header">
                <div className="date-container">
                  <div className="day">
                    <h3>{competencia.day}</h3>
                  </div>
                  <div className="month">
                    <h4>{competencia.month}</h4>
                  </div>
                </div>
                <div className="type-container">
                  <h5 className="type">{competencia.type}</h5>
                </div>
              </div>
              <div className="card-main">
                <h3>{competencia.name}</h3>
                <div className="spacer"></div>
                <h6>
                  {competencia.city}-{competencia.location}
                </h6>
                <div className="card-footer">
                  <a
                    href={competencia.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    + Info
                  </a>
                  <div className="bookmark"></div>
                </div>
              </div>{" "}
            </article>
          ))}
        </div>
      )}
    </div>
  );
};

export default Cards;
