import React, { useState, useEffect } from "react";
import Menu from "./components/Menu";
import Intro from "./components/Intro";
import Cards from "./components/Cards";

import "./css/styles.css";

function App() {
  const [filters, setFilters] = useState({
    type: "Todos",
    month: "Todos",
    ano: "Todos",
  });

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  // Guardar los valores iniciales de filtros en localStorage al cargar la aplicación
  useEffect(() => {
    localStorage.setItem("racefinder_filters", JSON.stringify(filters));
  }, [filters]);

  return (
    <div className="App">
      <Menu onFilterChange={handleFilterChange} filters={filters} />
      <Intro />
      <Cards filters={filters} />
    </div>
  );
}

export default App;
